//------------------------------------------------------------------------
// Dynamically load Vimeo player using API
// https://github.com/vimeo/player.js/
//
// See markup example in /app/views/shared/shortcodes/_vimeo.html.erb
//
// TODO: Pause currently playing audio/video players when starting a new one
//------------------------------------------------------------------------
/*global Vimeo*/
"use strict";
import loadJS from "fg-loadjs";

class VimeoPlayer {
  constructor(el) {
    this.el = el;
    // Get Vimeo video ID
    this.videoId = this.el.getAttribute("data-vimeo");
    // Set default player options
    // https://github.com/vimeo/player.js#embed-options
    this.options = {
      autoplay: false,
      byline: false,
      color: "00c1d5",
      id: this.videoId,
      portrait: false,
      title: false// video title is displayed in the markup below the player
    };

    if (!this.videoId) {
      console.warn("Missing Vimeo video ID");
      return false;
    }

    // Get ID of element where video should be injected
    this.playerId = this.el.getAttribute("data-vimeo-player");

    if (!this.playerId) {
      console.warn("Missing ID of Vimeo video wrapper");
      return false;
    }

    // Find elements that need to be updated with video data
    this.titleEl = this.el.querySelectorAll('[data-vimeo-title]');
    this.timeEl = this.el.querySelectorAll('[data-vimeo-time]');
    this.imageEl = this.el.querySelectorAll('[data-vimeo-image]');

    // Update DOM with dynamic content (e.g. poster image, title, length)
    this.updateDOM();

    // Find link overlay
    this.linkEl = this.el.querySelector(".Video-link");

    if (!this.linkEl) {
      console.warn("Missing Vimeo player link overlay, loading video immediately", this.videoId);
      this.buildPlayer();
      return false;
    }

    // Load video player on click
    this.linkEl.addEventListener("click", evt => {
      evt.preventDefault();

      // Create the video player if not already present
      if (!this.player) {
        // Enable autoplay so users don’t have to click twice
        this.options.autoplay = true;
        this.buildPlayer();
      }
    });
  }

  // Plain JS AJAX request
  // https://plainjs.com/javascript/ajax/making-cors-ajax-get-requests-54/
  getCORS(url, success, error) {
    var xhr = new XMLHttpRequest();
    if (!("withCredentials" in xhr)) xhr = new XDomainRequest(); // fix IE8/9
    xhr.open("GET", url);
    xhr.onload = success;
    xhr.onerror = error;
    xhr.send();
    return xhr;
  }

  // Get video thumbnails using Vimeo oEmbed API to avoid having
  // to register for an API key and dealing with rate limits.
  // https://stackoverflow.com/a/34451013/673457
  getVideoData(callback) {
    let url =
      "https://vimeo.com/api/oembed.json?url=" +
      encodeURIComponent("https://vimeo.com/") +
      this.videoId;
    this.getCORS(
      url,
      request => {
        let response = request.currentTarget.response || request.target.responseText;
        callback(JSON.parse(response));
      },
      function() {
        console.warn("Failed to get Vimeo oEmbed data");
      }
    );
  }

  // Update DOM with dynamic content (e.g. poster image and title)
  updateDOM() {
    this.getVideoData(data => {

      // Update poster image
      if (this.imageEl.length) {
        this.addThumbnailImage(data);
      }

      // Update title text
      this.titleEl.forEach(el => {
        el.textContent = data.title;
      });

      // Update duration
      if (this.timeEl.length) {
        this.updateDuration(data);
      }
    });
  }

  // Add responsive poster image
  addThumbnailImage(data) {
    if (!("thumbnail_url" in data)) {
      console.warn("Could not find thumbnail image for video ID " + this.videoId);
      return false;
    }

    // Get the thumbnail URL and remove the image dimensions
    // e.g. https://i.vimeocdn.com/video/436080040_295x166.jpg
    const baseUrl = data.thumbnail_url.split("_")[0];

    // Available sizes determined by inspecting the API response on the Vimeo dev site
    // https://developer.vimeo.com/api/reference/videos#get_video_thumbnails
    // NOTE: The oEmbed API only provides a single thumbnail but we can use the ID in
    //       the base URL above to get the others.
    const sizes = ["295x166", "640x360", "960x540", "1280x720", "1920x1080"];

    // Build srcset attribute
    let srcset = "";

    sizes.forEach((val, index) => {
      const width = val.split("x")[0];

      if (index !== 0) {
        srcset += ", ";
      }

      srcset += `${baseUrl}_${val}.jpg ${width}w`;
    });

    // Use forEach() in case a video is embedded more than once on a page
    this.imageEl.forEach(el => {
      el.setAttribute("srcset", srcset);
    });
  }

  // Update duration (not included in oEmbed data, have to wait for player to load)
  updateDuration(data) {
    let duration = data.duration;
    let hours = Math.floor(duration / 3600);
    // Create “datetime” string for <time> tag
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/time
    let datetime = "PT";
    datetime += hours > 0 ? hours + "H" : "";
    let a11yText = hours > 0 ? hours + " hours " : "";
    hours = hours > 0 ? hours + ":" : "";
    duration %= 3600;

    let minutes = Math.floor(duration / 60);
    datetime += minutes > 0 ? minutes + "M" : "";
    a11yText += minutes > 0 ? minutes + " minutes " : "";
    minutes = ("0" + minutes).slice(-2);

    let seconds = duration % 60;
    a11yText += seconds > 0 ? seconds + " seconds" : "";
    datetime += seconds > 0 ? seconds + "S" : "";
    seconds = ("0" + seconds).slice(-2);

    this.timeEl.forEach(el => {
      el.setAttribute("datetime", datetime);
      el.setAttribute("aria-label", a11yText);
      el.setAttribute("aria-hidden", "false");
      el.textContent = hours + minutes + ":" + seconds;
    });
  }

  buildPlayer() {
    // Create video player
    this.player = new window.Vimeo.Player(this.playerId, this.options);

    // Focus the iframe since the link is now hidden
    this.player.ready().then(() => {
      this.player.element.focus();
    });
  }
}

const buildPlayers = els => {
  els.forEach(el => {
    new VimeoPlayer(el);
  });
};

const init = () => {
  let videos = document.querySelectorAll("[data-vimeo]");

  // Load JS API and create video players if present
  if (videos.length) {
    // Load JS API if not already loaded
    // https://github.com/vimeo/player.js
    if (typeof window.Vimeo == "undefined") {
      // Get the script then build the players
      loadJS("https://player.vimeo.com/api/player.js", function() {
        buildPlayers(videos);
      });
    } else {
      buildPlayers(videos);
    }
  }
};

init();

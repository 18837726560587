import Flickity from 'flickity';

// Ensures that carouel cells are equal height
// https://codepen.io/desandro/pen/ZXEGVq?editors=1010
Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};

Flickity.createMethods.push('_createResizeClass');

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call( this );
  this.element.classList.add('flickity-resize');
};

let carousel = document.querySelector('.Timeline-carousel');
let alertMessage = document.getElementById('CarouselAlert');

if (carousel) {
  let flkty = new Flickity(carousel, {
    adaptiveHeight: false,
    dragThreshold: 15,
    friction: 0.8,
    selectedAttraction: 0.1,// Higher attraction makes the slider move faster (default 0.025)
    cellAlign: 'left',
    contain: true,
    lazyLoad: 3,
    pageDots: false,
    groupCells: true,
    accessibility: true,
    arrowShape: 'M0 54.2L10.4 0v108.5L0 54.4v-.2z',
    on: {
    ready: function() {
      // Adds the custom icon's appropriate viewbox values
      let arrows = document.querySelectorAll('.flickity-button-icon');
      arrows.forEach(arrow => arrow.setAttribute('viewBox', '0 0 11 109'));

      // Update “role” attribute to account for markup changes after Flickity inits
      // (Flickity doesn’t work with ul/li so we have to use divs with “role” attrs)
      this.element.removeAttribute('role');
      carousel.querySelector('.flickity-slider').setAttribute('role', 'list');

      // Add resize class on initial load
      this.element.classList.add('flickity-resize');
      }
    }
  });

  // Indicating to the screenreader what slide the user is currently on when the slide has changed
  // https://www.w3.org/WAI/tutorials/carousels/functionality/
  // https://www.accessibility-developer-guide.com/examples/widgets/carousel/
  flkty.on('change', (index) => {
    let maxSlides = flkty.slides.length
    let currentSlide = index + 1;

    alertMessage.innerHTML = `<div role='alert'>Showing Slide ${currentSlide} of ${maxSlides}</div>`;

    // Clears out the alert message content
    setTimeout(function() {
      alertMessage.innerHTML = '';
    }, 2000);

  });
}
//------------------------------------------------------------------------
// Accessiblity menu functionality (font size and color theme chooser)
//
// NOTE: Saved theme preference is applied on load in critical.js to prevent a FOUC
//------------------------------------------------------------------------
"use strict";

import ExpandToggle from '@threespot/expand-toggle';

class A11yMenu {
  constructor(el) {
    var self = this;
    this.htmlEl = document.documentElement;
    this.el = el;
    this.textSizeEl = document.getElementById("text-size");
    this.themeInputs = this.el.querySelectorAll(".A11yMenu-theme input");

    // Init toggle
    this.expandToggle = new ExpandToggle(
      document.querySelector(".A11yMenu-toggle")
    );

    // Update form fields on load to reflect currently selected theme and font size
    this.updateForm();

    // Expand the menu on load if the user has never collapsed it before
    if (typeof localStorage !== "undefined" && !localStorage.a11yMenu) {
      this.expandToggle.expand()
    }

    // Set cookie once menu is collapsed
    this.expandToggle.on("collapse", () => {
      if (typeof localStorage !== "undefined" && !localStorage.a11yMenu) {
        localStorage.a11yMenu = true;
      }
    });

    // Listen for any changes within the form
    this.el.addEventListener("change", function(evt) {
      var val = evt.target.value;

      // If the value of the element that changed is not a number, assume it’s a theme class
      if (isNaN(parseInt(val, 10))) {
        self.updateTheme(val);
      } else {
        self.updateTextSize(val);
      }
    });

    // Close when click off of it
    window.addEventListener("click", evt => {
      if (!evt.target.closest(".A11yMenu")) {
        this.expandToggle.collapse();
      }
    });
  }

  updateTextSize(size) {
    // Apply inline font-size to html element
    this.htmlEl.style.fontSize = size + "%";

    // Save value to localStorage
    if (typeof localStorage !== "undefined") {
      localStorage.textSize = size;
    }

    // Trigger window resize event to force all expandable components to recalc their height
    window.dispatchEvent(new Event('resize'));
  }

  updateTheme(themeColor) {
    // Find any class names starting with “t-” and remove them
    var htmlClasses = this.htmlEl.className.replace(/\b(\s*?t-.+?)\b/g, "");

    // Add new theme class
    this.htmlEl.className = htmlClasses + " t-" + themeColor;

    // Save to localStorage
    if (typeof localStorage !== "undefined") {
      localStorage.theme = themeColor;
    }
  }

  // Get current theme and font size values from localStorage and update form fields
  updateForm() {
    if (typeof localStorage !== "undefined") {
      if (localStorage.theme) {
        // Select the current theme radio button
        for (var i = 0, len = this.themeInputs.length; i < len; i++) {
          var input = this.themeInputs[i];

          if (input.value == localStorage.theme) {
            input.checked = true;
          }
        }
      }

      if (localStorage.textSize) {
        // Add “selected” attribute to current font size
        this.textSizeEl.querySelector(
          '[value="' + localStorage.textSize + '"]'
        ).selected = true;
      }
    }
  }
}

// Init
var a11Menu = new A11yMenu(document.querySelector(".A11yMenu-form"));

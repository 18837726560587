//------------------------------------------------------------------------
// Load the Recite Me toolbar when users click the toggle button
// https://github.com/Threespot/blindness/issues/454
// https://portal.reciteme.com/portal/product/installation/client/e5f6079d59bf23c13a8f868da5d273971977d547/p/1
//------------------------------------------------------------------------
import loadJS from 'fg-loadjs';

class ReciteMeToggle {
  constructor(el) {
    // Check if Recite Me has previously been enabled,
    // and if so, load it immediately.
    if (sessionStorage.reciteme && sessionStorage.reciteme == 'true') {
      this.loadScript();
    }

    // Load Recite Me on toggle click
    el.addEventListener('click', evt => {
      // Check if it’s already been loaded first
      if (typeof Recite == 'undefined') {
        this.loadScript();
      }
    });
  }

  loadScript() {
    loadJS('https://api.reciteme.com/asset/js?key=e5f6079d59bf23c13a8f868da5d273971977d547', () => {
      Recite.load({
        Tracker: {
          Ga: { enabled: true }
        }
      });

      Recite.Event.subscribe("Recite:load", () => {
        // console.log('Recite:load');
        Recite.enable();
        sessionStorage.reciteme = true;
      });

      Recite.Event.subscribe("Recite:disable", () => {
        // console.log('Recite:disable');
        sessionStorage.reciteme = false;
      });

      // These events can be used to check the current theme styles
      // to update the logo and icon colors.

      // Recite.Event.subscribe('Preferences:load', function() {
      //   console.log('Preferences:load', Recite.Preferences.get());
      // });

      // Recite.Event.subscribe('Preferences:set', function() {
      //   console.log('Preferences:set', Recite.Preferences.get());
      // });

      // Recite.Event.subscribe('Preferences:reset', function() {
      //   console.log('Preferences:reset', Recite.Preferences.get());
      // });

      // Example output from Recite.Preferences.get('style')
      //
      // dictionary: {enabled: false}
      // language: null
      // magnifier: {enabled: false}
      // player: {autoplay: true, continuePlay: false, playbackSpeed: 1}
      // playerControls: {enabled: true}
      // style: {
      //   backgroundColor: null
      //   charSpacing: null
      //   font: {color: null, face: null, size: null}
      //   lineHeight: null
      //   link: {color: null}
      //   marginSize: null
      //   ruler: {enabled: false, color: null}
      //   screenMask: {enabled: false, color: null}
      //   textAlign: null
      //   wwHighlight: {color: null}
      // }
      // textMode: {enabled: false}
      // voice: {gender: "f"}
      // voices: {en: "Amy"}
    });
  }
}

// Init
document.querySelectorAll('.A11yMenu-tools-toggle').forEach(el => {
  new ReciteMeToggle(el);
});

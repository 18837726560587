//------------------------------------------------------------------------
// Dynamically adds the ".is-hovered" class ThreeUp's img tag when
// user hovers over the ThreeUp Card's title link.
//------------------------------------------------------------------------
let threeUpLinks = document.querySelectorAll('.ThreeUp-link');

// Retrieves the ThreeUp img from an element
function findThreeUpImg(element) {
  let parent = element.parentElement;

  // We are checking each parent element until we find the ThreeUp-card class
  // This loop will automatically bail once it reaches the body element and no class was found to
  // prevent an infinite loop
  while(!parent.classList.contains('ThreeUp-card') || document.body === parent) {
    parent = parent.parentElement;
  }

  // regardless of finding the element or not we are doing one more search for the three-up image
  return parent.querySelector('.ThreeUp-img-src');
}

if (threeUpLinks) {
  threeUpLinks.forEach(link => {
    link.addEventListener('mouseover', event => {
      let threeUpImg = findThreeUpImg(event.target);

      if (!threeUpImg) {
        return null;
      }

      threeUpImg.classList.add('is-hovered');
    });

    link.addEventListener('mouseout', event => {
      let threeUpImg = findThreeUpImg(event.target);

      if (!threeUpImg) {
        return null;
      }

      threeUpImg.classList.remove('is-hovered');
    });
  });
}